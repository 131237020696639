import React, { useContext, useEffect, useRef, useState } from 'react';
import './Parcours.css';
import Parcours from '../../../../components/parcours/Parcours';
import FilledButton from '../../../../components/molecules/buttons/filledButton/FilledButton';
import Bracket from '../../../../components/atoms/icons/general/bracket/Bracket';
import TooltipModal from '../../../../components/organisms/tooltipModal/TooltipModal';
import { ParcoursContext } from '../../../../context/ParcoursContext';
import Breadcrumbs from '../../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../../components/molecules/banner/Banner';
import {
  fetchAllActivitesByIdApi,
  fetchCompteResultatSpecialInputs,
  fetchTypesCommerceApi,
} from '../../../../api/ParcoursApi';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSirenData, fetchToken } from '../../../../api/InseeApi';
import { formatNumberWithSpaces } from '../../../../utils/Utils';
import StepsRow from '../../../../components/organisms/stepsRow/StepsRow';
import AppContext from '../../../../context/AppContext';
import SecondaryParcours from '../../../../components/parcours/SecondaryParcours';
import { fetchEstimationApi, updateEstimationAPI, validateEstimationApi } from '../../../../api/EstimationApi';
import { Link } from 'react-router-dom';
import ArrowSm from '../../../../components/atoms/icons/general/arrowSm/ArrowSm';
import UnfilledButton from '../../../../components/molecules/buttons/unfilledButton/UnfilledButton';

function DetailParcours() {
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [estimationValues, setEstimationValues] = useState({});
  const [goToStep, setGoToStep] = useState(0);
  const [activites, setActivites] = useState([]);
  const [parcoursType, setParcoursType] = useState(2); // parcours = 2, secondaire = 4
  const [displayedRaisonsSociales, setDisplayedRaisonsSociales] = useState([]);
  const [inputsNames, setInputsNames] = useState([]);
  const [compteResultatInputs, setCompteResultatInputs] = useState({
    production_prestations_vendues: {},
    rest: [],
  });
  const [raisonSocialeWatcher, setRaisonSocialeWatcher] = useState('');
  const [stepsSummary, setStepsSummary] = useState([]);
  const [sirenAccessToken, setSirenAccessToken] = useState('');
  const [dependanceLocalCommercial, setDependanceLocalCommercial] = useState(false);
  const [mainBilanId, setMainBilanId] = useState(null);
  const [typesCommerce, setTypesCommerce] = useState([]);
  // 1: affaires concernées par le chiffre d’affaire hébergement + restauration
  // 2: affaires concernées par l’extraction
  // 3: affaires concernées par le % liquide
  // 4: affaires concernées par la réputation en ligne de Booking (càd : faire apparaître la case des com° nettes sur l'étape 4 du parcours)
  // 5: affaires concernées par le total des commissions nettes
  // 6: affaires pour lesquelles la fourchette CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 7: affaires pour lesquelles la fourchette EBE sur CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 8: hôtels
  // 9: CHR (SAUF Hôtel bureau ET Hôtel meublé - Appart'hôtel ET Résidence hôtelière)
  // 10: agence immobilière
  const submitFormsFdc = useRef(new Array(14).fill(null));
  const parcoursTopRef = useRef(null);
  const tooltipModalRef = useRef(null);
  const { estimationTypeParam, estimationIdParam } = useParams();
  const { createNotification, setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivites();
    setAccessToken();
    switch (estimationTypeParam) {
      case 'titres-de-societe':
        setParcoursType(2);
        break;
      case 'secondaire':
        setParcoursType(4);
        break;
      default:
        navigate('/404');
    }
  }, [estimationTypeParam]);

  useEffect(() => {
    if (!estimationIdParam || estimationIdParam === 'null') {
      setStep(1);
      return setEstimationValues({});
    }

    setAppLoaderVisible(true);

    fetchEstimation(estimationIdParam);
  }, [estimationIdParam]);

  useEffect(() => {
    focusInput(inputsNames[step - 1]);
    setIsConfirmButtonLoading(false);
  }, [step, parcoursType]);

  useEffect(() => {
    filterDisplayedRaisonsSociales();
  }, [raisonSocialeWatcher]);

  function focusInput(inputId) {
    parcoursTopRef.current && parcoursTopRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      document.getElementById(inputId)?.focus([{ preventScroll: true }]);
    }, 400);
  }

  async function setAccessToken() {
    try {
      const token = (await fetchToken()).data;
      setSirenAccessToken(token);
    } catch (e) {}
  }

  async function fetchEstimation(estimationId) {
    try {
      const response = await fetchEstimationApi(estimationId);
      setEstimationValues(response.data.estimation);
      setMainBilanId(
        response.data.related_estimation_id ? response.data.related_estimation_id : response.data.estimation.id,
      );

      await fetchTypesCommerce(response.data.estimation.infos.activite.id, response.data.estimation.id);

      if (parcoursType === 4) setStep(1);
      else if (response.data.estimation.step < 13) setStep(Number(response.data.estimation.step) + 1);
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--grey)',
      );
    }
    setAppLoaderVisible(false);
  }

  async function fetchActivites() {
    try {
      setActivites((await fetchAllActivitesByIdApi()).data);
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  async function fetchTypesCommerce(commerceId) {
    try {
      let res = (await fetchTypesCommerceApi(commerceId)).data;

      if (res.affaires) setTypesCommerce(res.affaires.map(affaire => affaire.id));

      res = (await fetchCompteResultatSpecialInputs(commerceId)).data;
      const inputs = {
        production_prestations_vendues: res.find(input => input.input_name === 'production_prestations_vendues'),
        rest: res.filter(
          inputs =>
            inputs.input_name !== 'production_prestations_vendues' && inputs.input_name !== 'total_commissions_nettes',
        ),
      };

      setCompteResultatInputs(inputs);
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de la récupérations de données. Veuillez réessayer plus tard.</>,
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  function previousStep() {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  function fillInputsFromEstimationValues(parent, fields, setValue, estimation = estimationValues) {
    if (!estimation) return;

    fields.forEach(field => {
      if (estimation[parent] && estimation[parent][field] !== null) setValue(field, estimation[parent][field]);
    });
  }

  function fillSelectsFromEstimationValues(parent, fields, setValue, estimation = estimationValues) {
    fields.forEach(field => {
      if (estimation?.[parent]?.[field]?.id) {
        setValue(field, estimation[parent][field].id + '');
      }
    });
  }

  function isLastStep() {
    if (parcoursType === 4) return step >= 4;
    return step >= 13;
  }

  function showTooltip(name) {
    if (tooltipModalRef.current) tooltipModalRef.current.scrollTo({ top: 0, behavior: 'instant' });

    setTooltipContent(name);
    setToolTipVisible(true);
  }

  async function filterDisplayedRaisonsSociales() {
    if (raisonSocialeWatcher === undefined || !sirenAccessToken) return;

    if (!raisonSocialeWatcher.length) return setDisplayedRaisonsSociales([]);

    try {
      const response = (await fetchSirenData(raisonSocialeWatcher, sirenAccessToken)).data.etablissements;
      setDisplayedRaisonsSociales(
        response.map((element, key) => {
          const adresse = element.adresseEtablissement;
          return {
            id: key,
            nom: element.uniteLegale.denominationUniteLegale || '',
            siret: formatNumberWithSpaces(element.siret, true) || '',
            naf: element.uniteLegale.activitePrincipaleUniteLegale || '',
            rue: `${adresse.numeroVoieEtablissement || ''} ${adresse.typeVoieEtablissement || ''} ${
              adresse.libelleVoieEtablissement || ''
            }`.trim(),
            code_postal: adresse.codePostalEtablissement || '',
            ville: adresse.libelleCommuneEtablissement || '',
          };
        }),
      );
    } catch (e) {}
  }

  function selectRaisonSociale(e, id) {
    const raisonSociale = displayedRaisonsSociales.find(element => element?.id === id);

    setEstimationValues(prevState => {
      return {
        ...prevState,
        infos: {
          raison_sociale: raisonSociale?.nom,
          enseigne: raisonSociale?.nom,
          siret: raisonSociale?.siret,
          ville: raisonSociale?.ville,
          rue: raisonSociale?.rue,
          code_postal: raisonSociale?.code_postal,
        },
      };
    });

    setDisplayedRaisonsSociales([]);
    moveToNextStep(2);
  }

  function getEstimationId() {
    if (estimationIdParam) return estimationIdParam;
    return estimationValues?.id;
  }

  async function postValues(values) {
    setIsConfirmButtonLoading(true);
    try {
      const payload = {
        ...{
          id: getEstimationId(),
          step: Math.max(step, estimationValues.step != undefined ? estimationValues.step : 0),
          type: {
            id: parcoursType,
          },
          ...values,
        },
      };
      if (!getEstimationId()) delete payload.id;

      const response = await updateEstimationAPI(payload);

      if (!estimationIdParam) {
        window.history.replaceState(
          null,
          null,
          `/detail-parcours/${estimationTypeParam}/${response.data.estimation.id}`,
        );
        fetchTypesCommerce(response.data.estimation.infos.activite.id, response.data.estimation.id);
      }
      setEstimationValues(response.data.estimation);

      if (isLastStep()) {
        await validateEstimationApi(response.data.estimation.id);
        navigate(`/synthese/${response.data.estimation.id}`);
        createNotification('Votre estimation a été sauvegardée avec succès');
      }

      return response.data.estimation.id;
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la sauvegarde de l'étape {step}.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => setStep(step)}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--grey)',
      );
      throw error;
    } finally {
      setIsConfirmButtonLoading(false);
    }
  }

  function moveToNextStep(index) {
    if (isLastStep()) return;
    setStep(index + 1);
  }

  async function submitStep() {
    await submitFormsFdc?.current[step]();
  }

  async function saveAndQuit() {
    await submitStep();
    createNotification('Votre estimation a été sauvegardée avec succès');
    navigate(`/synthese/${estimationValues.id}`);
  }

  function changeStepFromSummary(goToStep) {
    if (goToStep < step) return setStep(goToStep);
    submitStep();
    setGoToStep(Math.min(goToStep, estimationValues?.step != undefined ? Number(estimationValues.step) + 1 : 0));
    setTimeout(() => {
      setGoToStep(0);
    }, 500);
  }

  function getStepToGo() {
    return document.getElementById('goToStep').value;
  }

  function isStepChecked(actualStep) {
    return (actualStep <= estimationValues?.step || actualStep < step) && actualStep != step;
  }

  return (
    <>
      <TooltipModal
        isVisible={toolTipVisible}
        tooltip={tooltipContent}
        setTooltip={setTooltipContent}
        setIsVisible={setToolTipVisible}
        ref={tooltipModalRef}
      />
      <ParcoursContext.Provider
        value={{
          step,
          parcoursTopRef,
          getStepToGo,
          setStepsSummary,
          showTooltip,
          estimationValues,
          fillInputsFromEstimationValues,
          setRaisonSocialeWatcher,
          inputsNames,
          setInputsNames,
          typesCommerce,
          setTypesCommerce,
          activites,
          setStep,
          moveToNextStep,
          postValues,
          submitStep,
          submitFormsFdc,
          dependanceLocalCommercial,
          setDependanceLocalCommercial,
          fillSelectsFromEstimationValues,
          goToStep,
          setGoToStep,
          compteResultatInputs,
        }}>
        <div className='page-container'>
          <section className='container'>
            <Breadcrumbs
              routes={[
                {
                  name: 'Accueil',
                  to: '/',
                },
                {
                  name: "Estimer: Parcours d'estimation",
                  to: `/detail-parcours/${estimationTypeParam}${estimationIdParam ? `/${estimationIdParam}` : ''}`,
                },
              ]}
            />
            <Banner
              title='Evaluation en ligne'
              subtitle={
                parcoursType === 4 ? (
                  <Link className='link-with-arrow' to={`/synthese/${mainBilanId}`}>
                    <ArrowSm /> Retour à la synthèse
                  </Link>
                ) : (
                  <Link className='link-with-arrow' to='/mon-compte/mes-estimations'>
                    <ArrowSm />
                    Retour à mes estimations
                  </Link>
                )
              }
            />
          </section>
          <input type='hidden' value={goToStep} id='goToStep' />
          <div ref={parcoursTopRef} />
          <StepsRow
            stepsGroup={stepsSummary}
            actualStep={step}
            onStepClick={changeStepFromSummary}
            isStepChecked={isStepChecked}
          />
          <section className='container relative'>
            {parcoursType === 4 ? <SecondaryParcours /> : <Parcours />}
            <div className={'carousel-buttons ' + (!parcoursType !== 4 && step == 2 ? ' step-2-buttons' : '')}>
              {step == 1 ? (
                <div />
              ) : (
                <FilledButton height='50px' bgColor='var(--secondary-color)' onClick={previousStep}>
                  <Bracket rotation='270deg' width='18px' />
                  Etape précédente
                </FilledButton>
              )}
              {estimationValues?.validated && step > 1 && step < 13 && (
                <>
                  <UnfilledButton height='50px' onClick={saveAndQuit}>
                    Valider et terminer
                  </UnfilledButton>
                </>
              )}
              <FilledButton height='50px' color='var(--white)' onClick={submitStep} isLoading={isConfirmButtonLoading}>
                {isLastStep() ? (
                  'Valider'
                ) : (
                  <>
                    Valider et continuer
                    <Bracket color='var(--white)' rotation='90deg' width='18px' />
                  </>
                )}
              </FilledButton>
            </div>
            <div className='raisons-sociales-dropdown'>
              <ul
                className={
                  'raisons-sociales-container' + (displayedRaisonsSociales?.length && step == 2 ? '' : ' h-none')
                }>
                {displayedRaisonsSociales.map((raisonSociale, key) => (
                  <li
                    className='raison-sociale-container'
                    key={key}
                    onClick={e => selectRaisonSociale(e, raisonSociale.id)}>
                    <p className='raison-sociale-title'>{raisonSociale.nom}</p>
                    <div className='row'>
                      <p>
                        <span className='raison-sociale-label'>SIRET :</span>{' '}
                        <span className='raison-sociale-value'>{raisonSociale.siret}</span>
                      </p>
                      <p>
                        <span className='raison-sociale-label'>NAF :</span>{' '}
                        <span className='raison-sociale-value'>{raisonSociale.naf}</span>
                      </p>
                    </div>
                    <p className='raison-sociale-label'>ADRESSE</p>
                    <p className='raison-sociale-value'>{raisonSociale.rue}</p>
                    <p className='raison-sociale-value'>{raisonSociale.code_postal + ' ' + raisonSociale.ville}</p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </ParcoursContext.Provider>
    </>
  );
}

export default DetailParcours;
