import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../molecules/formComponents/input/Input.js';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import './DefinitionAffaire.css';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import { formatDate, getFormattedDate, normalizeString, removeSpaces } from '../../../../../utils/Utils.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import FileInputPreview, {
  getFileInputValue,
} from '../../../../molecules/formComponents/fileInputPreview/FileInputPreview.js';
import { deleteEstimationImageApi, updateEstimationImagesApi } from '../../../../../api/EstimationApi.js';
import AppContext from '../../../../../context/AppContext.js';

function DefinitionAffaire({ id, index }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    moveToNextStep,
    step,
    activites,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [activitesDropdownVisible, setActivitesDropdownVisible] = useState(false);
  const [displayedActivites, setDisplayedActivites] = useState(activites);
  const [images, setImages] = useState({ image_1: null, image_2: null, image_3: null, image_4: null });
  const watchActivite = watch('activite');
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'infos',
      ['enseigne', 'ville', 'code_postal', 'rue', 'raison_sociale', 'siret', 'capital_social'],
      setValue,
      estimationValues,
    );
    if (estimationValues?.infos?.date_bilan) setValue('date_bilan', formatDate(estimationValues?.infos?.date_bilan));
    else setValue('date_bilan', formatDate('31/12/2023'));
    setValue('activite', estimationValues?.infos?.activite?.description);
    setValue('note_step_3', estimationValues?.notes?.note_step_3);

    setImages({
      image_1: estimationValues?.images?.image_1,
      image_2: estimationValues?.images?.image_2,
      image_3: estimationValues?.images?.image_3,
      image_4: estimationValues?.images?.image_4,
    });
  }, [estimationValues]);

  useEffect(() => {
    if (!watchActivite) return setDisplayedActivites(activites);

    setDisplayedActivites(
      activites.filter(element =>
        normalizeString(element.description).toLowerCase().includes(normalizeString(watchActivite).toLowerCase()),
      ),
    );
  }, [activites, watchActivite]);

  async function postImages(estimationId, values) {
    try {
      const payload = {
        image_1: getFileInputValue(values.image_1),
        image_2: getFileInputValue(values.image_2),
        image_3: getFileInputValue(values.image_3),
        image_4: getFileInputValue(values.image_4),
      };

      const res = (await updateEstimationImagesApi(estimationId, payload)).data;

      setImages({
        image_1: res.image_1_path,
        image_2: res.image_2_path,
        image_3: res.image_3_path,
        image_4: res.image_4_path,
      });
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de vos images. Veuillez réessayer</>,
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  async function onImageClear(e, name) {
    try {
      if (!estimationValues.id) return;
      await deleteEstimationImageApi(estimationValues.id, name);
    } catch (e) {}
  }

  async function formSubmit(values) {
    try {
      let activiteId;
      if (activites.length) {
        activiteId = activites.find(element => element.description === values.activite)?.id;
        if (!activiteId) {
          setError('activite', { message: 'Veuillez choisir une activité dans la liste' }, { shouldFocus: true });
          document.getElementById('activite').focus();
          return;
        }
      }

      if (values.image_1 || values.image_2 || (values.image_3 && !values.image_4))
        createNotification(<>Envoi des images en cours, cela peut prendre quelques instants.</>);

      values.capital_social = parseInt(removeSpaces(values.capital_social), 10);

      let payload = {
        infos: values,
      };

      payload.infos.activite = { id: activiteId };
      payload.infos.date_bilan = getFormattedDate(payload.infos.date_bilan);

      const estimationId = await postValues(payload);

      await postImages(estimationId, values);

      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    } catch (error) {
      const response = error.response ? error.response.data : null;
      if (response && response.http_response_code === 422 && response.error.includes('already provided')) {
        const year = values.date_bilan.slice(-4);
        createNotification(<>L'année {year} est déjà utilisée pour cette estimation.</>, 'var(--red)', 'var(--grey)');
      } else {
        createNotification(
          <>Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer</>,
          'var(--red)',
          'var(--grey)',
        );
      }
    }
  }

  function setChosenActivite(activite) {
    setValue('activite', activite.description);

    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  function closeDropwdown() {
    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>définissons l’affaire à évaluer</h2>
        <Input
          setValue={setValue}
          placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
          label='activité (vous ne pourrez pas la changer par la suite)'
          name='activite'
          register={register}
          disabled={estimationValues?.infos?.activite?.description ? true : false}
          error={errors?.activite && errors.activite.message}
          icon='search'
          onFocus={() => setActivitesDropdownVisible(true)}
          onBlur={closeDropwdown}
          preventAutoComplete
          validation={{}}
          bgColor='var(--main-color)'>
          <ul className={'activites-list' + (activitesDropdownVisible ? '' : ' h-none')}>
            {displayedActivites.map((activite, key) => {
              return (
                <li key={key} onClick={() => setChosenActivite(activite)}>
                  {activite.description}
                </li>
              );
            })}
            {displayedActivites.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
          </ul>
        </Input>
        <Input
          setValue={setValue}
          label='enseigne / nom de l’affaire à évaluer'
          register={register}
          error={errors?.enseigne && errors.enseigne.message}
          name='enseigne'
        />
        <Input
          setValue={setValue}
          label='n°/nom de rue'
          register={register}
          error={errors?.rue && errors.rue.message}
          name='rue'
        />
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='ville'
            register={register}
            error={errors?.ville && errors.ville.message}
            name='ville'
          />
          <Input
            setValue={setValue}
            label='code postal'
            register={register}
            error={errors?.code_postal && errors.code_postal.message}
            name='code_postal'
          />
        </div>
        <div className='row-1000'>
          <Input
            setValue={setValue}
            label='raison sociale'
            register={register}
            error={errors?.raison_sociale && errors.raison_sociale.message}
            name='raison_sociale'
          />
          <Input
            setValue={setValue}
            label='siret'
            validation={numberValidation}
            register={register}
            error={errors?.siret && errors.siret.message}
            name='siret'
          />
        </div>
        <NumberInput
          useForm={Form}
          label='capital social'
          error={errors?.capital_social && errors.capital_social.message}
          name='capital_social'
          icon='euro'
        />
        <Input
          setValue={setValue}
          name='date_bilan'
          register={register}
          error={errors?.date_bilan && errors.date_bilan.message}
          type='date'
          label={
            <>
              date du dernier bilan
              <Info fill='var(--grey)' onClick={() => showTooltip('Tutoetape3FDCtitreschoixanneedernierbilan.webp')} />
            </>
          }
          bgColor='var(--main-color)'
        />
        <div className='row-1000 gap-md mt-md space-between'>
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='image_1'
            label='image 1'
            previewDefaultValue={images.image_1}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='image_2'
            label='image 2'
            previewDefaultValue={images.image_2}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='image_3'
            label='image 3'
            previewDefaultValue={images.image_3}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='image_4'
            label='image 4'
            previewDefaultValue={images.image_4}
            onClear={onImageClear}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default DefinitionAffaire;
